<template>
    <div>
        <b-modal :id="$route.name + 'EditModal'" title="Редактирование типа кузова" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true">
            <b-row>
                <b-col cols="12">
                    <b-input v-model="bodyType.name" placeholder="Наименование" />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="12" class="mt-1">
                    <v-select
                        v-model="bodyType.category_car_id"
                        :disabled="categoriesSelectDisabled"
                        :options="categories"
                        label="name"
                        :reduce="option => option.id"
                        placeholder="Категория"
                        
                    >
                        <template v-slot:no-options>
                            <span class="no-data-text">Нет доступных категорий</span>
                        </template>
                    </v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="bodyType.is_active" class="custom-control-primary">
                    {{ bodyType.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editBodyType"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        vSelect
    },
    data(){
        return {
            bodyType: {
                name: null,
                is_active: null,
                category_car_id: null,
            },
            categoriesSelectDisabled: false,
            submitButtonDisabled: false,
            categories: [],
        }
    },
    mounted() {
        this.getCategories()
    },
    methods: {
        clearData() {
            this.bodyType = {
                name: null,
                is_active: null,
                category_car_id: null,
            }
        },
        getCategories() {
            this.$http
            .get('directory/car-categories')
            .then(res => {
                this.categories = res.data
        })
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'EditModal')
        },
        editBodyType() {
            this.submitButtonDisabled = true
            this.$http.patch(`/car-settings/body-types/${this.bodyType.id}`, {
                category_car_id: this.bodyType.category_car_id,
                name: this.bodyType.name,
                is_active: this.bodyType.is_active = true ? 1 : 0
            })
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип кузова изменен!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
    },
    created() {
    // adds the event listener function that will handle the event
        GlobalEventEmitter.$on('openEditCarBodyTypeModal', (bodyType) => {
            this.$http.get(`/car-settings/body-types/${bodyType.id}/edit`)
            .then(res => {
                this.bodyType = res.data
                this.bodyType.is_active = this.bodyType.is_active ? true : false
                this.$bvModal.show(this.$route.name + 'EditModal')
            })
        })
    },
    beforeDestroy() {
        // removes event listener
        GlobalEventEmitter.$off('openEditCarBodyTypeModal')
    },
}
</script>