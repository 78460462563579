<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-car-body-type :options="bodyTypes" @editForm="editForm"/>
    </div>
    <modal-car-body-type :categories="categories" @refresh="refresh" />
    <modal-car-body-type-edit :categories="categories" @refresh="refresh" />
    <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
  </div>
</template>
<script>
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter.js'
import ModalCarBodyType from '@/views/component/Modal/ModalCarBodyType/ModalCarBodyType.vue'
import ModalCarBodyTypeEdit from '@/views/component/Modal/ModalCarBodyType/ModalCarBodyTypeEdit.vue'
import TableCarBodyType from '@/views/component/Table/tableCarBodyType.vue'
export default {
  components: {
    ModalCarBodyType,
    ModalCarBodyTypeEdit,
    TableCarBodyType
  },
  data() {
    return {
      bodyTypes: [],
      categories: [],
      showPreloader: false,
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Название' },
        { key: 'category', label: 'Категория' },
        { key: 'date_time', label: 'Создан' },
      ],
      selectedBodyType: null,
    }
  },
  mounted(){
    this.$store.commit('pageData/setdataCount', null)
    
    this.openFilter()
    this.getCategories()
  },
  methods: {
    editForm(bodyType) {
      GlobalEventEmitter.$emit('openEditCarBodyTypeModal', bodyType)
    },
    getCategories() {
      this.$http.get('/car-settings/categories', {params: {is_active: true}})
      .then((res) => {
        this.categories = res.data
      })
    },
    openFilter(){
      let allRoutes = this.$store.state.draggableTab.tabs
      let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
      let arrayRoutes = []
      allRoutes.forEach(element => {
        arrayRoutes.push(element.path)
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true
        this.$http
        .get(`${this.$route.name}`, {params: param}).then(res => {
          this.bodyTypes = res.data
          this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
          this.showPreloader = false
          this.$store.commit('REFRESH_DATA', false)
        })
      }else if(arrayRoutes.includes(this.$route.path) == false){
        this.$bvModal.show(this.$route.name + 'filter')
      }
    },
    refresh(){
      this.showPreloader = true
      this.$http
      .get('car-settings/body-types')
      .then(res => {
        this.bodyTypes = res.data
        this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
        this.showPreloader = false
        this.$store.commit('REFRESH_DATA', false)
      }).catch(err => {})
    },
    sendToParent(tableData){
      this.bodyTypes = tableData
      this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
    },
  },
  computed: {
    fetchingNewData(){
      return this.$store.state.refresh.fetchingNewData
    }
  },
  watch: {
    fetchingNewData(val){
      if (val){
        let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
        this.$http
        .get(`${this.$route.name}`, {params: params})
        .then(res =>{
          this.bodyTypes = res.data
          this.$store.commit('pageData/setdataCount', this.bodyTypes.length)
          this.$store.commit('REFRESH_DATA', false)
        })
      }
    }
  }
}
</script>